/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CombinedState, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  extractBoundaries,
  extractDocTypesBoundaries,
  // filterBoundariesBySlice,
  pageIdPositionPairs
} from 'common/utils/documentUtil';
import type { CustomDeepDocRecordPage, DocumentRecordActionType } from 'features/dee_review/types';
import {
  getDeepdocJobBoundaries,
  getDeepdocJobRecords,
  getPotentialDuplicateBoundaries,
  getSummaryDeepdocJobRecords,
  updateDeepDocRecordStatus
} from 'lib/apis/deepdoc';
import type {
  DeepDocJob,
  DeepDocJobBoundaryDuplicate,
  DeepDocJobBoundaryEntry,
  DeepDocJobWorkflowStatus,
  DeepDocRecordPage
} from 'lib/graphql/__generated__/graphql';
import { DeepDocJobBoundaryDuplicateSource } from 'lib/graphql/__generated__/graphql';
import { sortBy } from 'lodash';

import type {
  CustomDocTypeBoundary,
  DETECTION_SOURCE,
  IDocumentRecordInitialState,
  IPageToDeleteData
} from './type';
import type { RootState } from '..';

function createInitialState(): IDocumentRecordInitialState {
  return {
    document: undefined,
    loading: true,
    docTypeBoundaryProcessStates: {},
    selectedDocTypeBoundary: null,
    columnSpan: 2,
    potentialDuplicateBoundaries: [],
    potentialAiDuplicateBoundaries: [],
    potentialManualDuplicateBoundaries: [],
    allPotentialDuplicateBoundaries: [],
    selectedPotentialDuplicateBoundaries: null,
    pageToDelete: {},
    isSelectedDuplicateSaved: false,
    duplicateSource: 'AI',
    columnAPageDetails: { range: '', type: '', id: '' },
    columnBPageDetails: { range: '', type: '', id: '' },
    boundariesPageCache: {},
    duplicatesOnError: null,
    fetchPotentialDuplicateLoading: false,
    isSummaryMagnify: true,
    isScrollToBoundaryId: true
  };
}

function createExtraActions() {
  return {
    fetchDocumentRecord: createAsyncThunk<
      unknown,
      { jobId: string; sliceId?: string },
      { state: RootState }
    >('document/fetchDocumentRecord', async ({ jobId, sliceId }, { getState, rejectWithValue }) => {
      try {
        const userStates = getState().user;

        const reviewerRole = userStates.user?.role === 'REVIEWER';

        const promiseRecords = reviewerRole
          ? getDeepdocJobRecords(jobId)
          : getSummaryDeepdocJobRecords(jobId);
        const documentRecords = await promiseRecords.then(({ data }) => data);

        const data = documentRecords.job as DeepDocJob;

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const currentSlice = data.slices?.find((slice) => slice.id === sliceId);

        // const boundaries = filterBoundariesBySlice(data.boundaries?.data, currentSlice)

        const boundaries = data.boundaries?.data;

        const pages = sortBy(data.pages?.data ?? [], ['rank']);

        const docTypes = data.docTypes?.data;
        const progress = 0;
        const status = data.workflowStatus;

        const idPositionPairs = pageIdPositionPairs(pages as DeepDocRecordPage[]);
        const extractedBoundaries = extractBoundaries(boundaries ?? [], idPositionPairs);
        const extractedDocTypesBoundaries = extractDocTypesBoundaries(
          extractedBoundaries,
          docTypes
        );

        return {
          idPositionPairs,
          currentSlice,
          boundaries: extractedBoundaries,
          pages,
          docTypeBoundaries: extractedDocTypesBoundaries,
          docTypes,
          status,
          progress
        };
      } catch (err: unknown) {
        return rejectWithValue(err);
      }
    }),
    fetchAndUpdateJobBoundaries: createAsyncThunk<unknown, { jobId: string }, { state: RootState }>(
      'document/fetchAndUpdateJobBoundaries',
      async ({ jobId }, { getState, rejectWithValue }) => {
        try {
          const state = getState().document;
          const deepdocBoundaries = await getDeepdocJobBoundaries(jobId).then(({ data }) => data);
          const data = deepdocBoundaries.job as DeepDocJob;
          const boundaries = data.boundaries?.data;
          const boundariesPages = boundaries?.flatMap((boundary) => boundary.pages) ?? [];
          const copyIdPositionPairs: Record<string, DeepDocRecordPage> | undefined = {
            ...state.pageIdPosition
          };
          for (const boundary of boundariesPages) {
            if (boundary) {
              copyIdPositionPairs[String(boundary.id)] = {
                ...copyIdPositionPairs[String(boundary.id)],
                ...boundary
              };
            }
          }
          const pages = sortBy(Object.values(copyIdPositionPairs), ['rank']);
          const idPositionPairs = pageIdPositionPairs(pages as DeepDocRecordPage[]);
          const extractedBoundaries = extractBoundaries(boundaries ?? [], idPositionPairs);
          const extractedDocTypesBoundaries = extractDocTypesBoundaries(
            extractedBoundaries,
            state.docTypes
          );
          return {
            idPositionPairs,
            boundaries: extractedBoundaries,
            docTypeBoundaries: extractedDocTypesBoundaries
          };
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    ),
    updateDocumentRecordStatus: createAsyncThunk<
      unknown,
      { documentId: string; status: DeepDocJobWorkflowStatus },
      { state: RootState }
    >(
      'document/updateDocumentRecordStatus',
      async ({ documentId, status }, { rejectWithValue }) => {
        try {
          await updateDeepDocRecordStatus(documentId, status).then(({ data }) => data);
          return { status };
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    ),
    fetchPotentialDuplicateBoundaries: createAsyncThunk<
      unknown,
      { jobId: string },
      { state: RootState }
    >('document/fetchPotentialDuplicateBoundaries', async ({ jobId }, { rejectWithValue }) => {
      try {
        const potentialDuplicateBoundaries = await getPotentialDuplicateBoundaries(jobId).then(
          ({ data }) => (data.job as DeepDocJob).duplicates?.data
        );

        return {
          potentialDuplicateBoundaries
        };
      } catch (err: unknown) {
        return rejectWithValue(err);
      }
    })
  };
}

const extraActions = createExtraActions();

const createExtraReducers = (builder: any) => {
  const {
    pending: fetchDocumentRecordPending,
    fulfilled: fetchDocumentRecordFilled,
    rejected: fetchDocumentRecordRejected
  } = extraActions.fetchDocumentRecord;

  const {
    fulfilled: updateDocumentRecordStatusFulFilled,
    rejected: updateDocumentRecordStatusRejected
  } = extraActions.updateDocumentRecordStatus;

  const {
    pending: fetchPotentialDuplicateBoundariesPending,
    fulfilled: fetchPotentialDuplicateBoundariesFilled
  } = extraActions.fetchPotentialDuplicateBoundaries;

  const { fulfilled: fetchAndUpdateJobBoundariesFilled } = extraActions.fetchAndUpdateJobBoundaries;

  builder.addCase(
    fetchDocumentRecordPending,
    (state: CombinedState<IDocumentRecordInitialState>) => {
      state.loading = true;
    }
  );

  builder.addCase(
    fetchDocumentRecordRejected,
    (state: CombinedState<IDocumentRecordInitialState>, action: any) => {
      state.error = action.error;
      state.loading = false;
    }
  );

  builder.addCase(
    fetchDocumentRecordFilled,
    (
      state: CombinedState<IDocumentRecordInitialState>,
      action: PayloadAction<DocumentRecordActionType>
    ) => {
      const { idPositionPairs, boundaries, pages, status, progress, docTypes, docTypeBoundaries } =
        action.payload;
      state.pageIdPosition = idPositionPairs;
      state.boundaries = boundaries;
      state.pages = pages;
      state.status = status;
      state.progress = progress;
      state.docTypes = docTypes;
      state.docTypeBoundaries = docTypeBoundaries;
      state.loading = false;
    }
  );

  builder.addCase(
    fetchAndUpdateJobBoundariesFilled,
    (
      state: CombinedState<IDocumentRecordInitialState>,
      action: PayloadAction<{
        idPositionPairs: Record<
          string,
          {
            pos: number;
            presignedUrl: string;
          }
        >;
        boundaries: DeepDocJobBoundaryEntry[];
        docTypeBoundaries: CustomDocTypeBoundary;
      }>
    ) => {
      const { boundaries, docTypeBoundaries, idPositionPairs } = action.payload;
      state.pageIdPosition = idPositionPairs;
      state.boundaries = boundaries;
      state.docTypeBoundaries = docTypeBoundaries;
    }
  );

  builder.addCase(
    updateDocumentRecordStatusFulFilled,
    (
      state: CombinedState<IDocumentRecordInitialState>,
      action: PayloadAction<{ status: DeepDocJobWorkflowStatus }>
    ) => {
      state.status = action.payload.status;
    }
  );

  builder.addCase(
    updateDocumentRecordStatusRejected,
    (state: CombinedState<IDocumentRecordInitialState>, action: any) => {
      state.error = action.error;
    }
  );

  builder.addCase(
    fetchPotentialDuplicateBoundariesPending,
    (state: CombinedState<IDocumentRecordInitialState>) => {
      state.fetchPotentialDuplicateLoading = true;
    }
  );

  builder.addCase(
    fetchPotentialDuplicateBoundariesFilled,
    (
      state: CombinedState<IDocumentRecordInitialState>,
      action: PayloadAction<{
        potentialDuplicateBoundaries: DeepDocJobBoundaryDuplicate[];
      }>
    ) => {
      state.boundariesPageCache =
        state.boundaries?.reduce(
          (acc, boundary) => {
            acc[String(boundary.id)] = {
              id: boundary.id,
              data: boundary.pages,
              type: String(boundary.type),
              range: String(boundary.range),
              count: boundary.pages?.length,
              facility: String(boundary.facility),
              dateOfService: String(boundary.dateOfService),
              physician: String(boundary.physician)
            } as CustomDeepDocRecordPage;
            return acc;
          },
          {} as Record<string, CustomDeepDocRecordPage>
        ) ?? {};

      const AIDuplicate: DeepDocJobBoundaryDuplicate[] = [];
      const manualDuplicate: DeepDocJobBoundaryDuplicate[] = [];
      for (const potDuplicate of action.payload.potentialDuplicateBoundaries) {
        if (
          Boolean(state.boundariesPageCache[String(potDuplicate.boundaryA)]) &&
          Boolean(state.boundariesPageCache[String(potDuplicate.boundaryB)])
        ) {
          if (potDuplicate.source === DeepDocJobBoundaryDuplicateSource.Manual) {
            manualDuplicate.push(potDuplicate);
          } else {
            AIDuplicate.push(potDuplicate);
          }
        }
      }
      state.potentialAiDuplicateBoundaries = AIDuplicate.sort((a, b) =>
        String(a.id).localeCompare(String(b.id))
      );

      state.potentialManualDuplicateBoundaries = manualDuplicate.sort((a, b) =>
        String(a.id).localeCompare(String(b.id))
      );

      state.allPotentialDuplicateBoundaries = [...AIDuplicate, ...manualDuplicate].sort((a, b) =>
        String(a.id).localeCompare(String(b.id))
      );

      state.potentialDuplicateBoundaries =
        state.duplicateSource === 'AI'
          ? state.allPotentialDuplicateBoundaries
          : state.allPotentialDuplicateBoundaries.filter((item) => item.decision);

      if (state.selectedPotentialDuplicateBoundaries) {
        state.selectedPotentialDuplicateBoundaries =
          state.potentialDuplicateBoundaries.find(
            (pdb) => pdb.id === state.selectedPotentialDuplicateBoundaries?.id
          ) ?? state.potentialDuplicateBoundaries[0];
      } else {
        state.selectedPotentialDuplicateBoundaries = state.potentialDuplicateBoundaries[0];
      }
      state.fetchPotentialDuplicateLoading = false;
    }
  );
};

/**
 * Spreading boundary properties is required to ensure
 * that we can always scroll to the beginning of a boundary
 * @param state
 * @param {id: string}
 * @returns
 */
const selectActiveBoundary = (
  state: IDocumentRecordInitialState,
  action: PayloadAction<{ id: string }>
) => {
  const foundBoundary = state.boundaries?.find((boundary) => boundary.id === action.payload.id);
  state.selectedDocTypeBoundary = { ...foundBoundary };
  return state;
};

const selectActiveBoundaryFromNoBoundary = (
  state: IDocumentRecordInitialState,
  action: PayloadAction<{ id: string; range: string }>
) => {
  state.selectedDocTypeBoundary = {
    ...state.boundaries?.find((boundary) => boundary.id === action.payload.id),
    range: action.payload.range
  } as typeof state.selectedDocTypeBoundary & { range?: string };
  return state;
};

const slice = createSlice({
  name: 'document',
  initialState: createInitialState(),
  extraReducers: createExtraReducers,
  reducers: {
    injectBoundaryDataOnPage(state) {
      const boundaries = state.boundaries;
      const pagePositions = state.pageIdPosition;
      const pages = state.pages ?? [];

      const pageWithinBoundaries: Record<string, boolean> = {};

      boundaries?.map((boundary) => {
        const type = boundary.type;
        const boundaryId = String(boundary.id);
        const isCompleted = boundary.completed;
        const facility = boundary.facility;
        const physician = boundary.physician;
        const dateOfService = boundary.dateOfService;
        const range = boundary.range;
        const pagez = boundary.pages as DeepDocRecordPage[];
        if (!Array.isArray(pagez)) return;
        const pagezLen = pagez.length;
        pagez.map((page, idx) => {
          pageWithinBoundaries[String(page.id)] = true;
          const pagePos = pagePositions?.[String(page.id)].pos;
          if (!pagePos) return;
          const pageIndex = pagePos - 1;
          pages[pageIndex] = {
            ...pages[pageIndex],
            type,
            boundaryId,
            facility,
            physician,
            dateOfService,
            range,
            completed: isCompleted,
            isStart: idx === 0,
            isEnd: idx === pagezLen - 1
          };
        });
      });

      // Reset all the page that have no boundaries
      if (pages.length > Object.keys(pageWithinBoundaries).length) {
        pages.map((page) => {
          if (!pageWithinBoundaries[String(page.id)]) {
            page.type = '';
          }
          return page;
        });
      }
    },
    selectActiveBoundary,
    selectActiveBoundaryFromNoBoundary,
    setActiveBoundaryData(
      state,
      action: PayloadAction<{ data: (DeepDocJobBoundaryEntry & { range?: string }) | null }>
    ) {
      state.selectedDocTypeBoundary = { ...state.selectedDocTypeBoundary, ...action.payload.data };
    },
    setBoundaryProcessStates(
      state,
      action: PayloadAction<{
        id: string;
        data: Partial<{ loading: boolean; reviewed: boolean; isChanged: boolean }>;
      }>
    ) {
      const { id, data } = action.payload;

      if (!state.docTypeBoundaryProcessStates) state.docTypeBoundaryProcessStates = {};

      if (!(id in state.docTypeBoundaryProcessStates)) {
        state.docTypeBoundaryProcessStates[id] = {
          loading: false,
          reviewed: false,
          isChanged: false
        };
      }

      state.docTypeBoundaryProcessStates[id] = {
        ...state.docTypeBoundaryProcessStates[id],
        ...data
      };
    },
    resetBoundaryProcessStates(state) {
      state.docTypeBoundaryProcessStates = {};
    },
    setColumnSpan(state, action: PayloadAction<number>) {
      state.columnSpan = action.payload;
    },
    setSelectedDuplicate(state, action: PayloadAction<DeepDocJobBoundaryDuplicate | undefined>) {
      state.selectedPotentialDuplicateBoundaries = action.payload;
      // if(action.payload) {
      //   state.duplicateSource = state.selectedPotentialDuplicateBoundaries?.source === 'MANUAL' ? 'MANUAL' : 'AI'
      // }
    },
    setPageToDelete(state, action: PayloadAction<IPageToDeleteData>) {
      const { duplicateId, pageId } = action.payload;
      if (state.pageToDelete?.[`${duplicateId}-${pageId}`]) {
        delete state.pageToDelete[`${duplicateId}-${pageId}`];
      } else {
        state.pageToDelete = {
          ...state.pageToDelete,
          [`${duplicateId}-${pageId}`]: action.payload
        };
      }
    },
    clearPageToDelete(state) {
      state.pageToDelete = {};
    },
    selectedDuplicateIsSaved(state, action: PayloadAction<boolean>) {
      state.isSelectedDuplicateSaved = action.payload;
    },
    setDuplicateSource(state, action: PayloadAction<DETECTION_SOURCE>) {
      state.duplicateSource = action.payload;
      if (action.payload === 'MANUAL') {
        state.potentialDuplicateBoundaries = state.allPotentialDuplicateBoundaries?.filter(
          (item) => item.decision
        );
        state.selectedPotentialDuplicateBoundaries = null;
        return;
      }
      state.potentialDuplicateBoundaries = state.allPotentialDuplicateBoundaries;
      state.selectedPotentialDuplicateBoundaries = state.allPotentialDuplicateBoundaries?.[0];
    },
    setColumnAPageDetails(
      state,
      action: PayloadAction<{ range: string; type: string; id: string }>
    ) {
      state.columnAPageDetails = action.payload;
    },
    setColumnBPageDetails(
      state,
      action: PayloadAction<{ range: string; type: string; id: string }>
    ) {
      state.columnBPageDetails = action.payload;
    },
    addManualDuplicateData(state, action: PayloadAction<DeepDocJobBoundaryDuplicate>) {
      const duplicateData = action.payload;
      state.potentialManualDuplicateBoundaries = [
        ...(state.potentialManualDuplicateBoundaries ?? []),
        duplicateData
      ].sort((a, b) => String(a.id).localeCompare(String(b.id)));
      state.allPotentialDuplicateBoundaries = [
        ...(state.allPotentialDuplicateBoundaries ?? []),
        duplicateData
      ].sort((a, b) => String(a.id).localeCompare(String(b.id)));
    },
    errorOnDuplicate(state, action: PayloadAction<{ status: boolean; value?: [string, string] }>) {
      state.duplicatesOnError = action.payload.status ? action.payload.value : null;
    },
    createBoundary(
      state,
      action: PayloadAction<{ boundary: DeepDocJobBoundaryEntry; setAction?: boolean }>
    ) {
      const newBoundary = action.payload.boundary;
      const isSetAction = action.payload.setAction;
      const boundaries = [...(state.boundaries ?? []), newBoundary];
      const newPageMap =
        newBoundary.pages?.reduce<Record<string, DeepDocRecordPage>>((acc, page) => {
          acc[String(page?.id)] = page as DeepDocRecordPage;
          return acc;
        }, {}) ?? {};
      const pages = sortBy(
        state.pages?.map((pag) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (newPageMap[String(pag.id)]) return { ...pag, ...newPageMap[pag.id as string] };
          return pag;
        }),
        ['rank']
      );
      const idPositionPairs = pageIdPositionPairs(pages as DeepDocRecordPage[]);
      const extractedBoundaries = extractBoundaries(boundaries, idPositionPairs);
      const extractedDocTypesBoundaries = extractDocTypesBoundaries(
        extractedBoundaries,
        state.docTypes
      );
      state.boundaries = extractedBoundaries;
      state.docTypeBoundaries = extractedDocTypesBoundaries;
      if (isSetAction) {
        selectActiveBoundary(state, { payload: { id: String(newBoundary.id) }, type: '' });
      }
    },
    updateBoundary(state, action: PayloadAction<DeepDocJobBoundaryEntry>) {
      const updatedBoundary = action.payload;
      state.boundaries = state.boundaries?.map((boundary) => {
        if (boundary.id === updatedBoundary.id) return { ...boundary, ...updatedBoundary };
        return boundary;
      });
      const idPositionPairs = state.pageIdPosition;
      if (!idPositionPairs) return;
      const extractedBoundaries = extractBoundaries(state.boundaries ?? [], idPositionPairs);
      const extractedDocTypesBoundaries = extractDocTypesBoundaries(
        extractedBoundaries,
        state.docTypes
      );
      state.boundaries = extractedBoundaries;
      state.docTypeBoundaries = extractedDocTypesBoundaries;
    },
    toggleSummaryMagnify(state) {
      state.isSummaryMagnify = !state.isSummaryMagnify;
    },
    toggleScrollToBoundaryId(state, action: PayloadAction<boolean>) {
      state.isScrollToBoundaryId = action.payload;
    }
  }
});

// exports
export const documentActions = { ...slice.actions, ...extraActions };
export const documentReducer = slice.reducer;
