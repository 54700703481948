import { graphql } from '../__generated__/gql';

export const GET_JOBS = graphql(`
  query jobs($limit: Int!, $offset: Int!, $filter: JobFilterInput) {
    jobs(limit: $limit, offset: $offset, filter: $filter) {
      data {
        id
        serviceType
        createdAt
        title
        status
        organization {
          id
          name
        }
        ... on DeepDocJob {
          parentJob {
          id
          title
        }
          progress
          taskType
          pageCount
          workflowStatus
          slaDatetime
          hasGeneratedResult
          hasGeneratedResultArchive
          satisfaction
          pageCount
          slices {
            id
            status
            deadline
            task
            meta
            user {
              id
              email
              name
            }
          }
        }
      }
      offsetInfo {
        start
        total
        next
        prev
        count
      }
    }
  }
`);

export const GET_PRESIGNED_URL = graphql(`
  query uploadUrl {
    uploadUrl {
      url
    }
  }
`);

export const GET_JOB_BY_ID = graphql(`
  query job($id: ID!) {
    job(id: $id) {
      id
      title
      serviceType
      createdAt
      organization {
        name
        id
      }
      ... on DeepDocJob {
      uploader {
        email
        }
          parentJob {
          id
          title
        }
        childJobs {
          id
          status
          title
          workflowStatus
          pageCount
        }
        taskType
        workflowStatus
        progress
        slices {
          id
          status
          deadline
          task
          meta
          user {
            id
            email
            name
          }
        }
        template {
          id
        }
        slaDatetime
        boundaries {
          data {
            completed
            reviewed
          }
        }
        pages {
          data {
            id
            pageNumber
          }
        }
      }
    }
  }
`);

export const GET_JOB_DATA_BY_ID = graphql(`
  query jobData($id: ID!) {
    job(id: $id) {
      id
      title
      serviceType
      createdAt
      organization {
        name
        id
      }
      ... on DeepDocJob {
        workflowStatus
        taskType
        isGeneratingResult
        hasGeneratedResult
        progress
        slaDatetime
        template {
          id
        }
      }
    }
  }
`);

export const GET_JOB_STATUS_BY_ID = graphql(`
  query jobStatus($id: ID!) {
    job(id: $id) {
      id
      ... on DeepDocJob {
        taskType
        workflowStatus
      }
    }
  }
`);

export const GET_JOB_DOWNLOAD_URL = graphql(`
  query getDeepDocJobDownloadUrl($id: ID!, $withArchive: Boolean!) {
    getDeepDocJobDownloadUrl(id: $id, withArchive: $withArchive) {
      url
    }
    job(id: $id) {
      title
    }
  }
`);

export const GET_JOB_FILES_BY_ID = graphql(`
  query jobFilesData($id: ID!) {
    job(id: $id) {
      files(limit: 1000, offset: 0) {
        data {
          createdAt
          id
          presignedUrl
          ... on DeepDocJob {
            createdAt
            id
            presignedUrl
          }
        }
      }
    }
  }
`);
